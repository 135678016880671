<template>
  <iq-card>
    <template v-slot:headerTitle>
      <h4 class="card-title">{{ title }}</h4>
    </template>
    <template v-slot:body>
      <div class="scroll bg-transparent" style="overflow-x: hidden">
        <div v-if="loadding" class="d-flex justify-content-center mb-3 mt-5">
          <b-spinner
            style="width: 3rem; height: 3rem"
            label="Large Spinner"
            type="grow"
            variant="primary"
          ></b-spinner>
          <span class="h4 mt-2 text-primary"> {{ $t("app.loading") }}... </span>
        </div>
        <TimeLine
          v-else
          :items="timelineItems"
          style="width: 540px"
          @showCurrentItem="showCurrentNotification"
        />
        <div class="text-center" v-if="timelineItems.length <= 0 && !loadding">
          <h6>{{ $t("notifications.not-notifications") }}</h6>
        </div>
      </div>
    </template>
  </iq-card>
</template>
<script>
export default {
  props: {
    timelineItems: {
      type: Array,
    },
    loadding: {
      type: Boolean,
    },
    title: {
      type: String,
    },
  },
  methods: {
    showCurrentNotification(notification) {
      this.$emit("showCurrentNotification", notification);
    },
  },
};
</script>
