<template>
  <b-container fluid class="mb-3">
    <b-row>
      <b-col :md="12">
        <iq-card>
          <template v-slot:body>
            <div class="d-flex justify-content-between align-items-center">
              <div class="todo-date d-flex mr-3">
                <i :class="`ri-calendar-2-line text-success mr-2`"></i>
                <span class="h5">{{ $t("sidebar.notifications") }}</span>
              </div>
              <div class="todo-notification d-flex align-items-center">
                <b-button
                  variant="outline-primary"
                  @click="$bvModal.show('bv-modal-notification')"
                  size="lg"
                  >{{ $t("notifications.created") }}</b-button
                >
              </div>
            </div>
          </template>
        </iq-card>
      </b-col>
      <!-- <b-col md="6">
        <NotificationComponent
          :title="$t('notifications.all-notifications')"
          :timelineItems="timelineItems"
          :loadding="notificationsLoading"
          @showCurrentNotification="showCurrentNotification"
        />
      </b-col> -->
      <b-col md="12">
        <NotificationComponent
          :title="$t('notifications.sent-by-me')"
          :timelineItems="sendByMe"
          :loadding="notificationsLoading"
          @showCurrentNotification="showCurrentNotification"
        />
      </b-col>
    </b-row>
    <b-modal
      hide-footer
      id="bv-modal-notification"
      :title="$t('notifications.created')"
    >
      <FormCreate @close="$bvModal.hide('bv-modal-notification')" />
    </b-modal>
    <b-modal
      hide-footer
      id="bv-modal-show-notification"
      :title="$t('notifications.show')"
    >
      <section v-if="currentNotification">
        <h6>{{ $t("notifications.title") }}</h6>
        <p class="mt-1">
          {{ currentNotification.title }}
        </p>
        <h6>{{ $t("notifications.date") }}</h6>
        <p class="mt-1">
          {{ currentNotification.right }}
        </p>
        <h6>{{ $t("notifications.scope") }}</h6>
        <p class="mt-1">
          {{ getScopeNotification(currentNotification) }}
        </p>
        <h6>
          {{
            currentNotification.type_model_send == scopeCourseId
              ? $t("courses.name")
              : $t("colleges.name")
          }}
        </h6>
        <p class="mt-1">
          {{ currentNotification.model.name }}
        </p>
        <h6>{{ $t("notifications.message") }}</h6>
        <p class="mt-1">
          {{ currentNotification.message }}
        </p>
      </section>
    </b-modal>
  </b-container>
</template>
<script>
import { core } from "@/config/pluginInit";
import FormCreate from "./Components/Create.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import NotificationComponent from "./Components/notificationComponent.vue";
import "moment/locale/es"; // without this line it didn't work
moment.locale("es");

export default {
  name: "Dashboard3",
  mounted() {
    core.index();
    this.fetchAllNotifications();
    this.fetchNotificationsSendedUser();
  },
  components: {
    FormCreate,
    NotificationComponent,
  },
  data() {
    return {
      currentNotification: null,
      scopeCourseId: 1,
      scopeSchoolId: 2,
    };
  },
  methods: {
    ...mapActions({
      fetchAllNotifications: "fetchAllNotifications",
      fetchNotificationsSendedUser: "fetchNotificationsSendedUser",
    }),
    setBg() {
      // It's generating a random hex color.
      return "#" + Math.floor(Math.random() * 16777215).toString(16);
    },
    getWrapedContent(message) {
      if (message.length >= 256) {
        return message.slice(0, 256) + "...";
      }
      return message;
    },
    showCurrentNotification(notification) {
      this.currentNotification = notification;
      this.$bvModal.show("bv-modal-show-notification");
    },
    getScopeNotification(notification) {
      return notification
        ? this.scopeOptions.find(
            (item) => item.id == notification.type_model_send
          ).name
        : "";
    },
  },
  computed: {
    ...mapGetters({
      getAllNotifications: "getAllNotifications",
      notificationsLoading: "notificationsLoading",
      getSendedNotifications: "getSendedNotifications",
    }),
    scopeOptions() {
      return [
        { id: this.scopeCourseId, name: "Por Curso" },
        { id: this.scopeSchoolId, name: "Todo el Colegio" },
      ];
    },
    timelineItems() {
      return this.getAllNotifications.map((item) => ({
        id: item.id,
        color: this.setBg(),
        title: item.title,
        right:
          moment(item.date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm") +
          " hrs.",
        description: this.getWrapedContent(item.message),
        message: item.message,
        child: {
          type: "img",
          items: [],
        },
        ...item,
      }));
    },
    sendByMe() {
      return this.getSendedNotifications.map((item) => ({
        id: item.id,
        color: this.setBg(),
        title: item.title,
        right:
          moment(item.date, "YYYY-MM-DD HH:mm").format("DD/MM/YYYY HH:mm") +
          " hrs.",
        description: this.getWrapedContent(item.message),
        message: item.message,
        child: {
          type: "img",
          items: [],
        },
        ...item,
      }));
    },
  },
};
</script>
<style>
.scroll {
  max-height: 610px;
  overflow-y: auto;
}
</style>
