<template>
  <ValidationObserver ref="form" v-slot="{ handleSubmit }">
    <form class="mt-2 mb-2" novalidate @submit.prevent="handleSubmit(onSubmit)">
      <!-- SCOPE -->
      <ValidationProvider
        vid="scope"
        :name="$t('notifications.scope')"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="scope">{{ $t("notifications.scope") }}</label>
          <v-select
            v-model="data.type_model_send"
            :placeholder="$t('notifications.scope')"
            :options="scopeOptions"
            label="name"
            :class="errors.length > 0 ? ' is-invalid' : ''"
          >
            <template #no-options="{}"> {{ $t("app.not-found") }}... </template>
          </v-select>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <!-- COURSES -->
      <ValidationProvider
        vid="courses"
        :name="$t('sidebar.courses')"
        :rules="showCourses ? 'required' : ''"
        v-slot="{ errors }"
        v-if="showCourses"
      >
        <div class="form-group">
          <label for="courses">{{ $t("sidebar.courses") }}</label>
          <v-select
            v-model="data.model_id"
            :placeholder="$t('sidebar.courses')"
            :options="getCourses"
            label="name"
            :class="errors.length > 0 ? ' is-invalid' : ''"
          >
            <template #option="{ grade, name, schoolPeriod }">
              <p style="margin: 0">
                {{ `${grade.name} - ${name}` }}
              </p>
              <br />
              <cite>{{ schoolPeriod.name }}</cite>
            </template>
            <template #selected-option="{ grade, name, schoolPeriod }">
              <p style="margin: 0">
                {{ `${grade.name} - ${name}` }}
              </p>
              <br />
              <cite>{{ schoolPeriod.name }}</cite>
            </template>
            <template #no-options="{}"> {{ $t("app.not-found") }}... </template>
          </v-select>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <ValidationProvider
        vid="grades"
        :name="$t('sidebar.grades')"
        :rules="showGrades ? 'required' : ''"
        v-slot="{ errors }"
        v-if="showGrades"
      >
        <div class="form-group">
          <label for="grades">{{ $t("sidebar.grades") }}</label>
          <v-select
            v-model="data.model_id"
            :placeholder="$t('sidebar.grades')"
            :options="getGrades"
            label="name"
            :class="errors.length > 0 ? ' is-invalid' : ''"
          >
            <template #option="{ name, typeEducation }">
              <p style="margin: 0">
                {{ `${typeEducation.name} - ${name}` }}
              </p>
            </template>
            <template #selected-option="{ name, typeEducation }">
              <p style="margin: 0">
                {{ `${typeEducation.name} - ${name}` }}
              </p>
            </template>
            <template #no-options="{}"> {{ $t("app.not-found") }}... </template>
          </v-select>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <!-- TITLE -->
      <ValidationProvider
        vid="title"
        :name="$t('notifications.title')"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="name">{{ $t("notifications.title") }}</label>
          <input
            type="text"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
            id="name"
            name="name"
            v-model="data.title"
            :placeholder="$t('notifications.title')"
            required
          />
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <!-- MESSAGE -->
      <ValidationProvider
        vid="message"
        :name="$t('notifications.message')"
        rules="required"
        v-slot="{ errors }"
      >
        <div class="form-group">
          <label for="message">{{ $t("notifications.message") }}</label>
          <b-form-textarea
            id="textarea"
            v-model="data.message"
            :placeholder="$t('notifications.message')"
            rows="3"
            max-rows="6"
            :class="
              'form-control mb-0' + (errors.length > 0 ? ' is-invalid' : '')
            "
          ></b-form-textarea>
          <div class="invalid-feedback">
            <span>{{ errors[0] }}</span>
          </div>
        </div>
      </ValidationProvider>
      <!-- MESSAGE -->
      <ValidationProvider
        vid="emergency"
        :name="$t('notifications.emergency')"
        rules=""
      >
        <div class="form-group">
          <b-form-checkbox
            id="emergency"
            v-model="data.emergency"
            name="emergency"
            class="mt-1"
            :value="1"
            :unchecked-value="0"
          >
            {{ $t("notifications.emergency") }}
          </b-form-checkbox>
        </div>
      </ValidationProvider>
      <hr />
      <!-- BUTTON ACTIONS -->
      <div class="d-inline-block w-100">
        <b-button
          type="submit"
          :disabled="false"
          variant="success"
          class="float-right"
        >
          <span v-if="statusButton">
            <b-spinner small type="grow"></b-spinner>
            {{ $t("app.loading") }}...
          </span>
          <span v-else>
            {{ $t("app.send") }}
          </span>
        </b-button>
        <b-button
          variant="outline-danger"
          type="cancel"
          @click="$emit('close')"
          class="float-right mr-1"
        >
          {{ $t("app.cancel") }}
        </b-button>
      </div>
    </form>
  </ValidationObserver>
</template>
<script>
// A way to import multiple functions from a module.
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import { core } from "@/config/pluginInit";
import {
  SCOPE_COLLEGE_OPTIONS,
  SCOPE_COLLEGE_OPTION_COURSE,
  SCOPE_COLLEGE_OPTION_GRADE,
} from "@/constants/college";
export default {
  mounted() {
    this.fetchCourses();
    this.fetchGrades();
  },
  watch: {
    "data.type_model_send": {
      handler() {
        if (this.data.type_model_send) {
          this.showCourses =
            this.data.type_model_send.id === SCOPE_COLLEGE_OPTION_COURSE
              ? true
              : false;
          this.showGrades =
            this.data.type_model_send.id === SCOPE_COLLEGE_OPTION_GRADE
              ? true
              : false;
        } else {
          this.showCourses = false;
          this.showGrades = false;
        }
      },
    },
  },
  data() {
    return {
      SCOPE_COLLEGE_OPTION_COURSE,
      SCOPE_COLLEGE_OPTION_GRADE,
      data: {
        type_model_send: "",
        model_id: "",
        title: "",
        message: "",
        emergency: 0,
        date_notification: moment().format("YYYY-MM-DD"),
        time_notification: moment().format("HH:mm"),
      },
      showCourses: false,
      showGrades: false,
      statusButton: false,
    };
  },
  methods: {
    ...mapActions({
      fetchCourses: "fetchCourses",
      fetchGrades: "fetchGrades",
      storeNotifications: "storeNotifications",
    }),
    async onSubmit() {
      this.statusButton = true;
      let {
        type_model_send,
        model_id,
        title,
        message,
        emergency,
        date_notification,
        time_notification,
      } = this.data;
      let payload = {
        type_model_send: type_model_send.id,
        model_id:
          type_model_send.id === SCOPE_COLLEGE_OPTION_COURSE ||
          type_model_send.id === SCOPE_COLLEGE_OPTION_GRADE
            ? model_id.id
            : this.collageAuthUser?.id,
        college_id: this.collageAuthUser?.id,
        title,
        message,
        emergency,
        date_notification,
        time_notification,
      };
      const resp = await this.storeNotifications(payload);
      this.$emit("close");
      if (resp.status === 200) {
        this.statusButton = false;
        core.showSnackbar(
          "success",
          !this.data.id
            ? this.$t("app.success-create")
            : this.$t("app.success-edit")
        );
      }
      this.reserForm();
    },
    reserForm() {
      (this.data = {
        type_model_send: "",
        model_id: "",
        title: "",
        message: "",
        emergency: 0,
        date_notification: moment().format("YYYY-MM-DD"),
        time_notification: moment().format("HH:mm"),
      }),
        (this.showCourses = false);
      this.showGrades = false;
    },
  },
  computed: {
    ...mapGetters({
      getCourses: "getCourses",
      getGrades: "getGrades",
      collageAuthUser: "collageAuthUser",
      notificationsLoading: "notificationsLoading",
    }),
    scopeOptions() {
      return SCOPE_COLLEGE_OPTIONS;
    },
  },
};
</script>
<style>
.vue-tel-input {
  border-radius: 7px !important;
  border: 1px solid rgb(204, 204, 204);
  padding: 3px;
}
.input-group > .input-group-append > .btn-group > .btn,
.input-group > .input-group-prepend:not(:first-child) > .btn-group > .btn,
.input-group
  > .input-group-prepend:first-child
  > .btn-group:not(:first-child)
  > .btn {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}
</style>
